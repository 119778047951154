import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Dashboard from './pages/dashboard/Dashboard';
import Strategy from './pages/strategy/StrategyList';
import Signal from './pages/signal/SignalList.js';
import Faq from './pages/static/faq/FaqList';
import UserList from './pages/user/UserList';
import EditUser from './pages/user/EditUser'
import AddFaq from './pages/static/faq/AddFaq';
import EditFaq from './pages/static/faq/EditFaq';
import Sidebar from './components/sidebar/Sidebar';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import ForgotPassword from './pages/auth/ForgotPassword';
import SubAdminList from './pages/subadmin/SubAdminList';
import AddSubAdmin from './pages/subadmin/AddSubAdmin';
import EditSubAdmin from './pages/subadmin/EditSubAdmin';
import UserFaq from './pages/static/UserFaq';
import Info from './pages/static/Info';
import Contact from './pages/static/ContactUs';
import ChangePassword from './pages/auth/ChangePassword';
import EditProfile from './pages/profile/Profile';
import ContactUs from './pages/static/Contact';
import ResetPassword from './pages/auth/ResetPassword';
import Encrypt from './pages/encryption/ExcelEncryption';
import AddStrategy from './pages/strategy/AddStrategy';
import AddSignal from './pages/signal/AddSignal';
import ViewSignal from './pages/signal/ViewSignal';
import ViewUser from './pages/user/ViewUser';
import EditSignal from './pages/signal/EditSignal';
import Symbol from './pages/symbol/SymbolList'
import AddSymbol from './pages/symbol/AddSymbol';
import Category from './pages/category/CategoryList'
import AddCategory from './pages/category/AddCategory';
import AddTag from './pages/tag/AddTag';
import Tag from './pages/tag/TagList'
import ViewStrategy from './pages/strategy/ViewStrategy';
import EditStrategy from './pages/strategy/EditStrategy';
import EditSymbol from './pages/symbol/EditSymbol';
import ViewSymbol from './pages/symbol/ViewSymbol';
import EditTag from './pages/tag/EditTag';
import EditCategory from './pages/category/EditCategory';
import TradeLog from './pages/tradelog/TradeLogList';
import AddTradeLog from './pages/tradelog/AddTradeLog';
import ViewTradeLog from './pages/tradelog/ViewTradeLog';
import EditTradLog from './pages/tradelog/EditTradeLog';
import Basket from './pages/basket/BasketList';
import AddBasket from './pages/basket/AddBasket';
import EditBasket from './pages/basket/EditBasket';
import ViewBasket from './pages/basket/ViewBasket';
import CMSPage from './pages/static/CMSPage';
import WatchList from './pages/watchlist/WatchList';
import AddWatchList from './pages/watchlist/AddWatchList';
import EditWatchList from './pages/watchlist/EditWatchList';
import ViewWatchlist from './pages/watchlist/ViewWatchlist';
import PastPerfomanceList from './pages/pastperfomance/PastPerfomanceList';
import Label from './pages/label/LabelList';
import AddLabel from './pages/label/AddLabel';
import EditLabel from './pages/label/EditLabel';
import StockDatabase from './pages/stockdatabase/SectorList';
import SubSectorList from './pages/stockdatabase/SubSectorList';
import PerfomanceAnalytics from './pages/pastperfomance/PerfomanceAnalytics';
import ViewAnalytics from './pages/pastperfomance/ViewAnalytics';
import ViewSubAdmin from './pages/subadmin/ViewSubAdmin';
import StockList from './pages/stockdatabase/StockList';
import Notification from './pages/notification/NotificationList';
import AddNotification from './pages/notification/AddNotification';
import EditWebinar from './pages/webinar/EditWebinarSettings';
import Theme from './pages/theme/ThemeList';
import AddTheme from './pages/theme/AddTheme';
import ViewTheme from './pages/theme/ViewTheme';
import EditTheme from './pages/theme/EditTheme';
import Market from './pages/market/MarketList';
import MarketCategoryList from './pages/marketcategory/MarketCategoryList';
import AddMarketCategory from './pages/marketcategory/AddMarketCategory';
import EditMarketCategory from './pages/marketcategory/EditMarketCategory';
import FnOList from './pages/fno/FnOList';
import AddFnO from './pages/fno/AddFnO';
import EditFnO from './pages/fno/EditFnO'
// import Encrypt from './pages/encryption/Encryption';
import EditWebsite from './pages/website/EditWebsiteSettings';
import AddHistoricalSignal from './pages/pastperfomance/AddHistoricalSignal';
import EditHistoricalSignal from './pages/pastperfomance/EditHistoricalSignal';
import WatchlistStocks from './pages/watchlist/WatchlistStocks';
import SWmemberList from './pages/swmember/SWmemberList';
import EditSettings from './pages/settings/EditSettings';
import KYCmemberList from './pages/kycmember/KYCmemberList';

function App() {

  if (!localStorage.getItem("AisLogin", false)) {
    return (
      <div className="App">

        {/* <BrowserRouter basename={'/admin'}>
         */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword/:id" element={<ResetPassword />} />
            <Route path="/UserFaq" element={<UserFaq />} />
            <Route path="/info" element={<Info />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  } else {
    return (
      <div className="App">
        <Helmet>
          <script src={process.env.PUBLIC_URL + "/assets/js/app.js"}></script>
        </Helmet>
        {/* <BrowserRouter basename={'/admin'}> */}
        <BrowserRouter>
          <Header />
          <Sidebar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/editProfile" element={<EditProfile />} />
            <Route path="/subadmin" element={<SubAdminList />} />
            <Route path="/subadmin/add" element={<AddSubAdmin />} />
            <Route path="/subadmin/edit/:id" element={<EditSubAdmin />} />
            <Route path="/user" element={<UserList />} />
            <Route path="/user/edit/:id" element={<EditUser />} />
            <Route path="/encrypt" element={<Encrypt />} />
            <Route path="/strategy" element={<Strategy />} />
            <Route path="/signal/view/:id" element={<ViewSignal />} />
            <Route path="/user/view/:id" element={<ViewUser />} />
            <Route path="/signal/edit/:id" element={<EditSignal />} />
            <Route path="/strategy/add" element={<AddStrategy />} />
            <Route path='/signal' element={<Signal />} />
            <Route path="/signal/add" element={<AddSignal />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/faq/add" element={<AddFaq />} />
            <Route path="/faq/edit/:id" element={<EditFaq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contactUs/:id" element={<ContactUs />} />
            <Route path="/logout" element={<Logout />} />
            <Route path='/symbol' element={<Symbol />} />
            <Route path="/symbol/add" element={<AddSymbol />} />
            <Route path='/category' element={<Category />} />
            <Route path="/category/add" element={<AddCategory />} />
            <Route path='/tag' element={<Tag />} />
            <Route path="/tag/add" element={<AddTag />} />
            <Route path="/strategy/view/:id" element={<ViewStrategy />} />
            <Route path="/strategy/edit/:id" element={<EditStrategy />} />
            <Route path="/symbol/edit/:id" element={<EditSymbol />} />
            <Route path="/symbol/view/:id" element={<ViewSymbol />} />
            <Route path="/tag/edit/:id" element={<EditTag />} />
            <Route path="/category/edit/:id" element={<EditCategory />} />
            <Route path='/tradelog' element={<TradeLog />} />
            <Route path="/tradelog/add" element={<AddTradeLog />} />
            <Route path="/tradelog/view/:id" element={<ViewTradeLog />} />
            <Route path="/tradelog/edit/:id" element={<EditTradLog />} />
            <Route path='/basket' element={<Basket />} />
            <Route path="/basket/add" element={<AddBasket />} />
            <Route path="/basket/edit/:id" element={<EditBasket />} />
            <Route path="/basket/view/:id" element={<ViewBasket />} />
            <Route path="/about-us" element={<CMSPage title='About us' page_name='about_us' class_name='about' />} />
            <Route path="/terms-conditions" element={<CMSPage title='Terms & Condition' page_name='term_condition' class_name='terms_conidtions' />} />
            <Route path="/privacy-policy" element={<CMSPage title='Privacy Policy' page_name='privacy_policy' class_name='privacy_policy' />} />
            <Route path='/watchlist' element={<WatchList />} />
            <Route path="/watchlist/add" element={<AddWatchList />} />
            <Route path="/watchlist/edit/:id" element={<EditWatchList />} />
            <Route path="/watchlist/view/:id" element={<ViewWatchlist />} />
            <Route path='/past_perfomance' element={<PastPerfomanceList />} />
            <Route path='/label' element={<Label />} />
            <Route path="/label/add" element={<AddLabel />} />
            <Route path="/label/edit/:id" element={<EditLabel />} />
            <Route path='/stock_database' element={<StockDatabase />} />
            <Route path='/sub_sectors/:id' element={<SubSectorList />} />
            <Route path='/perfomance-analytics' element={<PerfomanceAnalytics />} />
            <Route path='/view-analytics/:key/:value/:category?' element={<ViewAnalytics />} />
            <Route path="/subadmin/view/:id" element={<ViewSubAdmin />} />
            <Route path='/stocks/:id' element={<StockList />} />
            <Route path='/notification' element={<Notification />} />
            <Route path="/notification/add" element={<AddNotification />} />
            <Route path="/webinar" element={<EditWebinar />} />
            <Route path='/theme' element={<Theme />} />
            <Route path="/theme/add" element={<AddTheme />} />
            <Route path="/theme/view/:id" element={<ViewTheme />} />
            <Route path="/theme/edit/:id" element={<EditTheme />} />
            <Route path='/market_watch' element={<Market />} />
            <Route path='/market_category' element={<MarketCategoryList />} />
            <Route path="/market_category/add" element={<AddMarketCategory />} />
            <Route path="/market_category/edit/:id" element={<EditMarketCategory />} />
            <Route path='/fno' element={<FnOList />} />
            <Route path="/fno/add" element={<AddFnO />} />
            <Route path="/fno/edit/:id" element={<EditFnO />} />
            <Route path="/website" element={<EditWebsite />} />
            <Route path="/past_perfomance/add_historical_signal" element={<AddHistoricalSignal />} />
            <Route path="/past_perfomance/edit_historical_signal/:id" element={<EditHistoricalSignal />} />
            <Route path="/watchlist_stocks/:category" element={<WatchlistStocks />} />
            <Route path="/stockwiz_members" element={<SWmemberList />} />
            <Route path="/settings" element={<EditSettings />} />
            <Route path="kyc_members" element={<KYCmemberList />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
