import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addSubAdmin, getCountryList, getModuleList } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { module_not_create , module_not_change_status, module_not_delete, module_import , module_not_list , module_not_update , module_not_view} from '../../globals/constant';


export default function AddSubAdmin() {

    
    const [permissionData, setPermissionData] = useState([])
    const [sectionList, setSectionList] = useState([]);

    useEffect(() => {
        getModuleList({}).then((res) => {
            res = JSON.parse(res)
            console.log(res);
            if (res.code == 200) {
                setSectionList(res.data)

            }
        })  
    }, [])

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please enter name").matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field').max(128, "Name must be at most 128 character"),
        email: Yup.string().email().required("Please enter email"),
        country_code: Yup.string().required("Please select country code"),
        password: Yup.string().required("Please enter password").matches('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$', 'Password must contain at least 4 characters, 2 numbers, and 1 special character.'),
        phone: Yup.string().required("Please enter phone number").matches('^[0-9]', 'Only numbers are allowed for this field').min(8, "Phone must be at least 8 numbers").max(14, "Phone must be at most 14 numbers"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const navigate = useNavigate();

    function redirect() {
        navigate("/subadmin")
    }

    

    const onSubmit = (data) => {
        // if (permissionData.length > 0) {

        addSubAdmin({ "name": data.name.trimStart().trimEnd(), "email": data.email, "password": data.password, "country_code": data.country_code, "phone": data.phone, "permissions": sectionList }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/subadmin")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
        // } else {
        //     Swal.fire({
        //         position: 'top-end',
        //         icon: 'warning',
        //         title: "Please select permission",
        //         toast: true,
        //         showConfirmButton: false,
        //         timer: 2000
        //     })
        // }
    }



    const handleOnChange = (event, rightType, moduleId) => {
        // Create a deep copy of the moduleList to modify
        const updatedModuleList = sectionList.map((item) => {
            if (item._id === moduleId) {
                // Create a new object to avoid modifying the original one
                const updatedRights = { ...item.rights };
                updatedRights[rightType] = event.target.checked ? 1 : 0;
                return {
                    ...item,
                    rights: updatedRights,
                };
            }
            return item;
        });
        setSectionList(updatedModuleList);
    };
    // if (countryList == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <h5>Add Sub Admin Details</h5>
                        <hr></hr>
                        {
                            <form onSubmit={handleSubmit(onSubmit)} >

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Name</label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('name')} className="form-control" placeholder="Please enter name" />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Email</label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('email')} className="form-control" placeholder="Please enter email " />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Password</label>
                                            <div className='ml-3'>
                                                <input type="password" {...register('password')} className="form-control" placeholder="Please enter password" />
                                                <div className="invalid-feedback">{errors.password?.message}</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label className='ml-3'>  Country Code </label>
                                            <div className='ml-3'>

                                                <input type="text" readOnly {...register('country_code')} className="form-control" defaultValue='91' />
                                                {/* <select className="form-control" {...register('country_code')} >
                                            <option selected value="">Select country code</option>
                                                { countryList.map((item,key) => {
                                                    return(
                                                        <option value={item.phonecode}>{"+"+item.phonecode+" ("+item.name+")"}</option>
                                                )})}
                                        </select> */}
                                            </div>
                                            <div className="invalid-feedback">{errors.country_code?.message}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label className='ml-3'> Phone Number</label>
                                            <div className='ml-3'>
                                                <input type="text" {...register('phone')} className="form-control" placeholder="Please enter phone number" />
                                                <div className="invalid-feedback">{errors.phone?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <label
                                        className="form-label"
                                        htmlFor="validationTooltip04"
                                    >
                                        <h4>Sub Admin Rights</h4>
                                    </label>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label className="form-label" htmlFor="validationTooltip04">
                                                    <strong>Module</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Listing</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Add</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Update</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Delete</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Change Status</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>View</strong>
                                                </label>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    <strong>Import Sheet</strong>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {sectionList?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="col-md-12"
                                            >
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="validationTooltip04"
                                                        >
                                                         <strong>{item.module}</strong>   
                                                        </label>
                                                    </div>

                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_list",
                                                                        item._id
                                                                    )
                                                                }
                                                                defaultChecked={item.rights.is_list}
                                                                disabled={module_not_list.includes(item.class_name)}
                                                            />

                                                        </div>
                                                    </div>                                                    
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_create",
                                                                        item._id
                                                                    )
                                                                }
                                                                defaultChecked={item.rights.is_create}
                                                                disabled={module_not_create.includes(item.class_name)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                defaultChecked={item.rights.is_edit}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_edit",
                                                                        item._id
                                                                    )
                                                                }
                                                                disabled={module_not_update.includes(item.class_name)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                defaultChecked={item.rights.is_delete}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_delete",
                                                                        item._id
                                                                    )
                                                                }
                                                                disabled={module_not_delete.includes(item.class_name)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                defaultChecked={item.rights.is_change_status}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_change_status",
                                                                        item._id
                                                                    )
                                                                }
                                                                disabled={module_not_change_status.includes(item.class_name)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                onChange={(event) => {
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_view",
                                                                        item._id
                                                                    );
                                                                }}
                                                                defaultChecked={item.rights.is_view}
                                                                disabled={module_not_view.includes(item.class_name)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                id="flexCheckDefault"
                                                                type="checkbox"
                                                                disabled={item.slug !== "/symbol"}
                                                                onChange={(event) =>
                                                                    handleOnChange(
                                                                        event,
                                                                        "is_import_sheet",
                                                                        item._id
                                                                    )
                                                                }
                                                                defaultChecked={item.rights.is_import_sheet}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                               
                                <div className="form-group ml-3">

                                    <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                        Add
                                    </button>
                                    <button className="btn btn-danger btn-custom-cancel" onClick={redirect} > Cancel </button>

                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}