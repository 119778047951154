import moment from "moment";
export const module_not_create = ["user","about","privacy_policy","terms_conidtions","contact_us","tradelog","webinar","website","market_watch","stock_database", "sw_members"]
export const module_not_change_status = ["about","privacy_policy","terms_conidtions","contact_us","tradelog","past_perfomance","notification","webinar","website","market_watch","stock_database" , "sw_members"];
export const module_not_delete = ["about","privacy_policy","terms_conidtions","contact_us","tradelog","past_perfomance","webinar","website","market_watch","stock_database","sw_members"];
export const module_import = ["symbol","market_watch","stock_database"];
export const module_not_view = ["faq","tag","category","tradelog","past_perfomance","faq","notification","market_watch","stock_database","market_watch_category","sw_members"];
export const module_not_update = ["tradelog","notification","market_watch","stock_database","sw_members"];
export const module_not_list = []

// User Mdule 
export const kycOptions = [
    { label: 'Pending', value: "0" },
    { label: 'Done', value: "1" },
]

// User Mdule 
export const statusOptions = [
    { label: 'Inactive', value: "0" },
    { label: 'Active', value: "1" },
]

// User Mdule 
export const brokerConnectOptions = [
    { label: 'Yes', value: "1" },
    { label: 'No', value: "0" },
]

// Algo User Mdule 
export const algoUserOptions = [
    { label: 'Algo User', value: "1" },
    { label: 'Non Algo User', value: "0" },
]

export const typeOptions = [
    { label: 'Free', value: 1 },
    { label: 'Premium', value: 2 },
]

export const tradingDirectionOptions = [
    { label: 'LONG', value: "1" },
    { label: 'LONG', value: "2" },
]

// User Module
export const subscriptionTypeOptions = [
    { label: 'Trial', value: "1" },
    { label: 'Free', value: "2" },
    { label: 'Paid', value: "3" },
]


// Category Mdule 
export const moduleOptions = [
    { label: 'Strategy', value: "1" },
    { label: 'Signal', value: "2" },
    { label: 'Trade Log', value: "3" },
    { label: 'Baskets', value: "4" },
    { label: 'Watch List', value: "5" },
]

// Segment 
export const segmentOptions = [
    { label: 'Equity Cash', value: "1" },
    { label: 'Stock Futures', value: "2" },
    { label: 'Index Futures', value: "3" },
    { label: 'Stock Options', value: "4" },
    { label: 'Index Options', value: "5" },
    { label: 'Commodity Futures', value: "6" },
]

export const investingSegmentOptions = [
    { label: 'Equity Cash', value: "1" },
]

export const DATEPICKER_RANGE =  {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment().endOf('year')],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
}

export const DATETIME_FORMATE =  'Do MMM YYYY, hh:mm A';
export const DATE_FORMAT = 'Do MMM YYYY';  


export const labelOptions = [
    { label: 'New', value: "1" },
    { label: 'Featured', value: "2" },
    { label: 'Popular', value: "3" },

]

// Market Mdule 
export const marketModuleOptions = [
    { label: 'Market', value: "1" },
    { label: 'Heat Map', value: "2" },
]

export const itemsPerPage = 10;


// index options Mdule 
export const indexStockOptions = [
    { label: 'NIFTY', ltp_index: "NIFTY 50" , value : "NIFTY" },
    { label: 'NIFTY BANK', ltp_index: "NIFTY BANK" , value : "BANKNIFTY" },
    { label: 'NIFTY FINANCIAL SERVICES', ltp_index: "NIFTY FIN SERVICE" , value : "FINNIFTY" },
    { label: 'NIFTY MID SELECT', ltp_index: "NIFTY MIDCAP 50" , value : "MIDCPNIFTY"},
    { label: 'NIFTY NEXT 50', ltp_index: "NIFTY NEXT 50" , value : "NIFTYNXT50"},
]


export const setupOptions = [
    { label: 'IPO Base', value: "1" },
    { label: 'Base Buildup', value: "2" },
    { label: 'VCP', value: "3" },
    { label: 'Order Blocks (Bullish)', value: "4" },
    { label: 'Order Blocks (Bearish)', value: "5" },
    { label: 'Liquidity Sweep', value: "6" },
    { label: 'Break Out', value: "7" },
    { label: 'Break Down', value: "8" },
];

export const tradeRiskOptions = [
    { label: '0.25', value: "0.25" },
    { label: '0.5', value: "0.5" },
    { label: '0.75', value: "0.75" },
    { label: '1.0', value: "1.0" },
    { label: '1.25', value: "1.25" },
    { label: '1.50', value: "1.50" },
];

export const signalDisplayOptions = [
    { label: 'ALL Signals', value: 0 },
    { label: 'Historical Signals', value: 1 },
]

export const signalDraftOptions = [
    { label: 'ALL Signals', value: 0 },
    { label: 'Draft Signals', value: 1 },
]

export const watchListCategoryOptions = [
    { label: 'Intraday', value: "1" , days : '1 Day'},
    { label: 'Swing', value: "2", days : '2-15 Days' },
    { label: 'Medium Term', value: "3" , days :'15-60 Days'},
    { label: 'Long Term', value: "4" , days : '60+ Days' },
];

export const watchListSetupOptions = [
    { label: 'Pre Breakout Scan (VCP)', value: 1 },
    { label: 'Institutional Buying Zone (OB)', value: 2},
]