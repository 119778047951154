/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller, set } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addHistoricalSignal, getCategories, getSymbolExpiryList, getSymbols, getFnOSymbols, getSignalById, editHistoricalSignal } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';
import { segmentOptions, investingSegmentOptions, indexStockOptions, setupOptions, tradeRiskOptions } from '../../globals/constant';
import { getStockLTP, getFNOStockLTP, getSymbolTrades, getOptionsTrades, uploadImageS3 } from '../../common/Common'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

window.Buffer = window.Buffer || require("buffer").Buffer;



export default function EditHistoricalSignal() {

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter signal name").max(128, "Signal name must be at most 128 character"),
        // trade_category: Yup.object().required("Please select trade category"),
        // symbols: Yup.object().required("Please select symbol"),
        // buying_zone: Yup.string().required("Please enter buy percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // selling_zone: Yup.string().required("Please enter sell percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // chart_image: Yup.string().required("Please upload chart image"),
        // stype: Yup.string().required('Signal type is required').nullable(),
        // trade_direction: Yup.string().required('Trade direction is required').nullable(),

        // ep: Yup.array().min(1).of(Yup.string().required('Entry price is required')).required('Entry price is required').nullable(),
        // tp: Yup.array().min(1).of(Yup.string().required('Target price is required')).required('Target price is required').nullable(),
        // rs: Yup.array().min(1).of(Yup.string().required('Risk is required')).required('Risk is required').nullable(),
    });
    let { id } = useParams();
    const percentageInputRef = useRef(null)
    const formOptions = { mode: "onChange", resolver: yupResolver(validationSchema) };
    const { control, register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;
    const [type, setType] = useState(null)
    const [risk, setRisk] = useState({ value: 0, type: '' })
    const [imageErr, setImageErr] = useState('');
    const [reportErr, setReportErr] = useState('');
    const [tradeDirection, setTradeDirection] = useState(1);
    const [expiryList, setExpiryList] = useState([]);
    const [tradeType, setTradeType] = useState(1);
    const [symbolList, setSymbolList] = useState(null)
    const [fnoList, setFnoList] = useState(null);
    const [symbolOptions, setSymbolOptions] = useState(null);
    const [radioType, setRadioType] = useState(2);
    const [symbol, setSymbol] = useState(null);
    const [selectCategory, setSelectCategory] = useState(null);
    const [chartImage, seChartImage] = useState(null);
    const [exitChartImage, setExitChartImage] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [categories, setCategpries] = useState(null);
    const [segment, setSegment] = useState(null);
    const [LTP, setLTP] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [options, setOptions] = useState(null);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [newTrade, setNewTrade] = useState(null);
    const [profit, setProfit] = useState({ value: 0, type: '' });
    const [stopLoss, setStopLoss] = useState({ percentage: '', price: '' });
    const [target, setTarget] = useState({ percentage: '', price: '' });
    const [tradesData, setTradesData] = useState([]);
    const [buyingZone, setBuyingZone] = useState({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' });
    const [sellingZone, setSellingZone] = useState({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' });
    const [riskRewardRatio, setRiskRewardRatio] = useState(0)
    const [selectSetup, setSelectSetup] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [exitSelectedDate, setExitSelectedDate] = useState(null);
    const [closeValue, setCloseValue] = useState(null);
    const [signalDetails, setSignalDetails] = useState(null)
    const [category, setCategory] = useState(null)
    const [stopLossMessage, setStopLossMessage] = useState('');
    const [targetMessage, setTagetMessage] = useState('');
    useEffect(() => {
        if (id != undefined) {
            getSignalById({ "signal_id": id }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    setType(response.data.category)
                    setSignalDetails(response.data)
                    setBuyingZone(response.data.buying_zone);
                    setCloseValue(response.data.trade_close_price);
                    setSellingZone(response.data.selling_zone);
                    seChartImage(response.data.chart_image);
                    setExitChartImage(response.data.exit_chart_image);
                    setReportPDF(response.data.report_pdf);
                    setTarget(response.data.target_price);
                    setStopLoss(response.data.stoploss);
                    setTradeDirection(response.data.trade_direction);
                    setLTP(response.data.entry_price);
                    setProfit(response.data.profit)
                    setExitSelectedDate(new Date(response.data.exit_date ?? response.data.trade_close_date))
                    setSelectedDate(new Date(response.data.entry_date))
                    const foundSymbol = symbolList?.find((symbol) => symbol.value === response.data.symbol_id);
                    setSymbol(foundSymbol);
                    const foundObjects = setupOptions.filter((setupobj) => response.data.setup.includes(setupobj.label));
                    setSelectSetup(foundObjects);
                    const riskObj = tradeRiskOptions.find((riskItem) => riskItem.label == response.data.tradelog_risk.value)
                    setRisk(riskObj)
                    const foundCategory = categories?.find((category) => category.value === response.data.trade_category_id);
                    setSelectCategory(foundCategory);
                    const segmentObj = segmentOptions.find((segmentItem) => segmentItem.label == response.data.segment);
                    setSegment(segmentObj)
                    setProfit(response.data.profit)
                }
            });
        }
    }, [id, symbolList, categories]);
    console.log(exitSelectedDate, "==============================");
    const image = chartImage != null ? `${process.env.REACT_APP_S3URL}/chart_image/${chartImage}` : '';
    const exitimage = exitChartImage != null ? `${process.env.REACT_APP_S3URL}/chart_image/${exitChartImage}` : '';
    const pdf = reportPDF != null ? `${process.env.REACT_APP_S3URL}/report_pdf/${reportPDF}` : '';
    useEffect(() => {
        if (stopLoss.price) {
            const stopLossPrice = Number(stopLoss.price);
            if (tradeDirection == 1 && stopLossPrice >= LTP) {
                console.log("case 1");
                setStopLossMessage('Stoploss price must be less than Entry price');
            } else if (tradeDirection == 2 && stopLossPrice <= LTP) {
                console.log("case 2");

                setStopLossMessage('Stoploss price must be greater than Entry price');
            } else {
                setStopLossMessage('');
            }
        }
    }, [stopLoss.price, tradeDirection, LTP, stopLossMessage]);

    useEffect(() => {
        if (target.price) {
            const targetPrice = Number(target.price);
            if (tradeDirection == 1 && targetPrice <= LTP) {
                console.log("case 1");
                setTagetMessage('Target price must be greater than Entry price');
            } else if (tradeDirection == 2 && targetPrice >= LTP) {
                console.log("case 2");
                setTagetMessage('Target price must be less than Entry price');
            } else {
                setTagetMessage('');
            }
        }
    }, [target.price, tradeDirection, LTP]);
    useEffect(() => {
        if (id != undefined) {
            getSignalById({ "signal_id": id }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {

                    const foundCategory = categories?.find((category) => category.value === response.data.trade_category_id);
                    setSelectCategory(foundCategory);

                }
            });
        }
    }, [categories, id]);
    useEffect(() => {
        getSymbols().then((symbols) => {
            symbols = JSON.parse(symbols)
            if (symbols.code == 200) {
                setSymbolList(symbols.data);
            }
        });
    }, []);

    useEffect(() => {
        if (type) {
            getCategories({ "type": type == "Investing" ? 1 : 2, "module": 2 }).then((catgoryData) => {
                catgoryData = JSON.parse(catgoryData)
                if (catgoryData.code == 200) {
                    setCategpries(catgoryData.data)
                }
            });
        }
    }, [type]);

    const handleBuyingZoneChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\d*\.?\d*$/;
        if (regex.test(value)) {
            let Price = LTP;
            const updatedValues = { [name]: value };
            if (name === 'lowerRange') {
                updatedValues['lowerPrice'] = parseFloat(Price - parseFloat(value)).toFixed(2);
            } else if (name === 'upperRange') {
                updatedValues['upperPrice'] = parseFloat(parseFloat(Price) + parseFloat(value)).toFixed(2);
            }
            setBuyingZone((prevState) => ({ ...prevState, ...updatedValues }));
        }
    };

    const handleSellingZoneChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\d*\.?\d*$/;
        if (regex.test(value)) {
            let Price = target.price;
            const updatedValues = { [name]: value };
            if (name === 'lowerRange') {
                updatedValues['lowerPrice'] = parseFloat(Price - parseFloat(value)).toFixed(2);
            } else if (name === 'upperRange') {
                updatedValues['upperPrice'] = parseFloat(parseFloat(Price) + parseFloat(value)).toFixed(2);
            }
            setSellingZone((prevState) => ({ ...prevState, ...updatedValues }));
        }
    };

    const handleSLPercentageChange = (e) => {

        const percentage = e.target.value;
        const regex = /^\d*\.?\d*$/;
        let price;

        if (regex.test(percentage)) { // Regex to allow only numbers
            if (tradeDirection == '2') {
                price = (parseFloat(LTP) + (parseFloat(LTP) * (parseFloat(percentage) / 100))).toFixed(2);
            } else {
                price = (LTP - (LTP * (percentage / 100))).toFixed(2);
            }
            setStopLoss({ percentage, price });
        }


    };

    const handleSLPriceChange = (e) => {
        const price = e.target.value;
        const percentage = parseFloat(((LTP - price) / LTP) * 100).toFixed(2);
        setStopLoss({ percentage, price });
    };

    const handleTargetPercentageChange = (e) => {
        const percentage = (e.target.value);
        const regex = /^\d*\.?\d*$/;
        if (!isNaN(percentage) && regex.test(percentage)) {
            // const price = parseFloat(LTP * (1 + percentage / 100)).toFixed(2);
            let price;
            if (tradeDirection == '2') {
                price = (LTP * (1 - percentage / 100)).toFixed(2);
                // price = (parseFloat(LTP) + (parseFloat(LTP) * (parseFloat(percentage) / 100))).toFixed(2);
            } else {
                price = (LTP * (1 + percentage / 100)).toFixed(2);
            }
            setTarget({ percentage, price });
        } else {
            setTarget({ percentage: '', price: '' });
        }
    };

    const handleTargetPriceChange = (e) => {
        const price = parseFloat(e.target.value);
        if (!isNaN(price)) {
            const percentage = parseFloat(((price - LTP) / LTP) * 100).toFixed(2);
            setTarget({ percentage, price });
        } else {
            setTarget({ percentage: '', price: '' });
        }
    };
    const t = type != false ? type : '';
    useEffect(() => {
        getSymbols({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setSymbolList(response.data)
            }
        })
    }, [])

    useEffect(() => {
        function fetchList() {
            if (type === 'Investing') {
                setOptions(symbolList);
                setSymbolOptions(false);
            }
            else {
                if (segment?.value === "1") {
                    setOptions(symbolList);
                    setSymbolOptions(false);
                }
                else if (segment?.value === "3" || segment?.value === "5") {
                    setOptions(indexStockOptions)
                    setSymbolOptions(true);
                }
                else {
                    setOptions(fnoList)
                    setSymbolOptions(true);
                }
            }
        }
        fetchList()
    }, [symbolOptions, symbolList, type, segment, options, fnoList])


    useEffect(() => {
        getFnOSymbols({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setFnoList(response.data)
            }
        })
    }, [])
    useEffect(() => {
        if (stopLoss.price && LTP && closeValue) {
            const value = (closeValue - LTP) / (LTP - stopLoss.price).toFixed(2);
            setRiskRewardRatio(value.toFixed(2))
            const profitValue = (value * risk?.value).toFixed(2);
            setProfit({ value: profitValue, type: 'Percentage' });
        }
    }, [riskRewardRatio, risk, stopLoss, LTP, closeValue])
    useEffect(() => {
        if (showForm && symbol && type == 'Trading' && segment && segment.value !== '1' && segment.value !== '6') {

            if (segment.value === '2' || segment.value === '3') {

                getSymbolTrades(symbol.value).then((res) => {
                    if (res) {
                        setTradesData(res)

                        if (!newTrade) {
                            setNewTrade(res[0].symbol)
                            setSelectedCheckbox(res[0].expiry)
                        }
                    }
                });
            }
            if ((segment.value === '4' || segment.value === '5')) {
                getSymbolExpiryList({ "symbol": symbol.value }).then((response) => {
                    response = JSON.parse(response)
                    if (response.code == 200) {
                        setExpiryList(response.data)
                        if (!selectedCheckbox) {
                            setSelectedCheckbox(response.data[0].expiry)
                        }
                        const dateWithoutDashes = selectedCheckbox.replace(/-/g, "");
                        getOptionsTrades(symbol, segment, tradeType, dateWithoutDashes, LTP).then((res) => {
                            if (res) {
                                setTradesData(res)
                                if (!newTrade) {
                                    setNewTrade(res[0].symbol)
                                }
                            }
                        });
                    }
                })
            }

            if (newTrade) {
                getFNOStockLTP(newTrade, symbol.value).then((res) => {
                    setLTP(res.ltp)
                });
            }


        }
    }, [showForm, type, segment, symbol, selectedCheckbox, tradeType, newTrade, LTP])
    const navigate = useNavigate();
    function onClickEdit() {
        setImageErr('')
        setReportErr('');
        setSymbol(null)
        seChartImage(null)
        setReportPDF(null)
        setSellingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setBuyingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setSymbol(null);
        setLTP(null);
        setExpiryList([]);
        setTradesData([]);
        setTarget({ percentage: '', price: '' });
        setStopLoss({ percentage: '', price: '' });
        setProfit({ value: 0, type: '' });
        setRisk({ value: 0, type: '' });
        setNewTrade(null);
        setSelectedCheckbox(null);
    }

    function redirect() {
        navigate("/past_perfomance")
    }

    useEffect(() => {
        setTarget({ percentage: '', price: '' });
        setStopLoss({ percentage: '', price: '' });
        setProfit({ value: 0, type: '' });
        setRisk({ value: 0, type: '' });
        setSellingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setBuyingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setLTP(null)
    }, [newTrade])
    // For Signal Categoey [ Investing and trading]
    const handleSignalCategoryChange = (event) => {
        const value = event.target.value;
        setType(value)
        getCategoryList(value == "Investing" ? "1" : "2")
        setSegment(null)
    };
    console.log("Type :: ", type);

    // Handle Symbol Change 
    const handleSymbolChange = async (selectedOption) => {
        let stockValue;
        if (symbolOptions) {
            const params = segment.value === "3" || segment.value === "5" ? selectedOption.ltp_index : selectedOption.value;
            stockValue = await getFNOStockLTP(params, selectedOption.value);
        }
        else {
            stockValue = await getStockLTP(selectedOption.value);
        }
        if (stockValue) {
            setSymbol(selectedOption);
            setLTP(stockValue.ltp)
        }
    }
    function getCategoryList(type) {
        getCategories({ "type": type, "module": 2 }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setCategpries(response.data)

            }
        })
    }
    //s3 file upload - Chart image
    const handleFileInput = async (e, is_exit_image) => {
        const imageName = await uploadImageS3({ "dir_name": 'chart_image', 'file': e.target.files[0] })
        if (is_exit_image === 1) {
            setExitChartImage(imageName)
        }
        else {
            seChartImage(imageName)
        }
    }
    //s3 file upload - Report PDF
    const handlePDFInput = async (e) => {
        const pdfName = await uploadImageS3({ "dir_name": 'report_pdf', 'file': e.target.files[0] })
        setReportPDF(pdfName)
    }
    const onSubmit = (data) => {
        // if (reportPDF == null) {
        //     setReportErr('Please upload report pdf')
        // }
        // else {
        editHistoricalSignal({ "signal_id": id, "category": type, "type": radioType, "symbol": symbol, "entry_price": LTP, "target_price": target, "stoploss": stopLoss, "trade_category": selectCategory, "chart_image": chartImage, "exit_chart_image": exitChartImage, "report_pdf": reportPDF, "buying_zone": buyingZone, "selling_zone": sellingZone, "trade_direction": tradeDirection, "segment": segment, "tradelog_risk": risk, "profit": profit, "risk_reward_ratio": riskRewardRatio, "setup": selectSetup, "entry_date": selectedDate, "exit_date": exitSelectedDate, "trade_close_price": closeValue }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/past_perfomance")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
        // }
    }
    if (signalDetails == null || sellingZone == null || buyingZone == null) return <> </>

    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <div className='full-width'>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>

                                <div style={{ marginLeft: 'auto' }}>
                                    <div className="card-container m-0">
                                        <div className="card-body p-0">
                                            <h6>Summary : </h6>
                                            <div>
                                                <label htmlFor="name" className='mr-1'>Trade Risk On Capital :</label> {risk?.value}%<br />
                                                <label htmlFor="reward-per-lot" className='mr-1'>Potential Profit On Capital :</label> {profit?.value}%<br />
                                                <label htmlFor="entry-price-range" className='mr-1'>Risk Reward Ratio:</label> {riskRewardRatio}<br />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <h5>Edit Historical Signal</h5>
                            <hr></hr>
                            {
                                <><meta charset="UTF-8"></meta>
                                    <div style={{ height: '470px', overflowY: 'auto', overflowX: 'hidden' }}>
                                        <form onSubmit={handleSubmit(onSubmit)} >

                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6 d-flex '>
                                                        <label className="mr-3 font-weight-bold" htmlFor='type1'>Select Category :</label>
                                                        <div className="mr-3">
                                                            <input
                                                                type="radio"
                                                                id="type1"
                                                                name="type_signal"
                                                                value="Investing"
                                                                checked={type == "Investing"}
                                                                onChange={(e) => {
                                                                    setType(e.target.value);
                                                                }}
                                                                disabled={false}
                                                            /><label htmlFor='type1' className='ml-1'>Investing</label>

                                                            <input
                                                                type="radio"
                                                                id="type2"
                                                                name="type_signal"
                                                                value="Trading"
                                                                checked={type == "Trading"}
                                                                onChange={(e) => {
                                                                    setType(e.target.value);

                                                                }}
                                                                disabled={false}
                                                                className='ml-2'
                                                            /> <label htmlFor='type2' className='ml-1'>Trading</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>

                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label" > Tradelog Segment </label>
                                                        <div>
                                                            <Controller
                                                                name="segment"
                                                                control={control}
                                                                rules={{ required: 'Please select segment' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={investingSegmentOptions}
                                                                        value={segment}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            setSegment(selectedOption)
                                                                            setIsReadOnly((selectedOption.value === '3' || selectedOption.value === '4') && (type === 'Trading'))
                                                                            setShowForm((selectedOption.value !== '1' && selectedOption.value !== '6') && (type === 'Trading') ? true : false);
                                                                            onClickEdit()
                                                                        }}
                                                                        placeholder="Select Segment"
                                                                        isSearchable={true}
                                                                        required
                                                                    />
                                                                )}
                                                            />

                                                            <div className="invalid-feedback">{errors.segment?.message}</div>

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label htmlFor="mySelect2 " className='required-label'>Symbol </label>
                                                        <div className='custom-select-container'>
                                                            <Controller
                                                                name="content"
                                                                control={control}
                                                                {...register('symbols', { required: 'Please select country code' })}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={options}
                                                                        value={symbol}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        onChange={(selectedOption) => {
                                                                            setSymbol(selectedOption);
                                                                            // handleSymbolChange(selectedOption);
                                                                            // field.onChange(selectedOption);
                                                                            // onClickEdit()
                                                                        }}
                                                                        placeholder="Select Symbol"
                                                                        isSearchable={true}
                                                                        required
                                                                    />
                                                                )}
                                                            />

                                                            <div className="invalid-feedback">{errors.symbols?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                showForm && tradesData.length > 0 ? <>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold"> Expiry Dates </label>
                                                                <div>
                                                                    {expiryList.length > 0 ? <>

                                                                        {expiryList.map((item, index) => (
                                                                            item.expiry ? <>
                                                                                <label style={{ marginRight: '10px' }}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={item.expiry}
                                                                                        checked={selectedCheckbox === item.expiry}
                                                                                        onChange={(event) => { setSelectedCheckbox(event.target.value); setNewTrade(null) }}
                                                                                        className='mr-1'
                                                                                    />
                                                                                    {item.expiry}
                                                                                </label>
                                                                            </> : <>
                                                                            </>
                                                                        ))}
                                                                    </> : <>
                                                                        <span style={{ color: 'blue' }}> {selectedCheckbox}</span>
                                                                    </>}
                                                                </div>
                                                            </div>

                                                            {showForm && tradesData.length > 0 ? <>
                                                                <div className='col-md-6'>
                                                                    <label className="font-weight-bold required-label"> Available Trades </label>
                                                                    <div>
                                                                        {tradesData.map((item, index) => (
                                                                            <label style={{ marginRight: '10px' }}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={item.symbol}
                                                                                    checked={newTrade === item.symbol}
                                                                                    onChange={(event) => {
                                                                                        setNewTrade(event.target.value);
                                                                                        if (segment.value === '2' || segment.value === '3') {
                                                                                            setSelectedCheckbox(item.expiry);
                                                                                        }
                                                                                    }}
                                                                                    className='mr-1'
                                                                                />
                                                                                {item.name}
                                                                            </label>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </> : <>

                                                            </>}
                                                        </div>
                                                    </div></> : <> </>
                                            }
                                            <div className="form-group">
                                                <div className='row'>

                                                    <div className='col-md-6'>

                                                        <label className=" font-weight-bold">Select Trade Direction :</label>
                                                        <div className="">
                                                            <input
                                                                type="radio"
                                                                id="tradeDirection1"
                                                                name="trade_direction"
                                                                value="1"
                                                                checked={tradeDirection == 1}
                                                                onChange={(e) => setTradeDirection(e.target.value)}
                                                            />
                                                            <label htmlFor='tradeDirection1' className='ml-1'>LONG</label>
                                                            {(type === 'Trading' && ((segment?.value === '1' && selectCategory?.label === 'Intraday') || segment?.value !== '1')) ? <>
                                                                <input
                                                                    type="radio"
                                                                    id="tradeDirection2"
                                                                    name="trade_direction"
                                                                    value="2"
                                                                    checked={tradeDirection == 2}
                                                                    className='ml-2'
                                                                    onChange={(e) => setTradeDirection(e.target.value)}
                                                                />
                                                                <label htmlFor='tradeDirection2' className='ml-1'>SHORT</label>
                                                            </> : <></>}

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label" > Trade Categoey </label>
                                                        <div>
                                                            <Controller
                                                                name="trade_category"
                                                                control={control}
                                                                rules={{ required: 'Please select segment' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={categories}
                                                                        value={selectCategory}
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        onChange={(selectedOption) => {
                                                                            setSelectCategory(selectedOption)
                                                                        }}
                                                                        placeholder="Select Trade Category"
                                                                        isSearchable={true}
                                                                        required
                                                                    />
                                                                )}
                                                            />

                                                            <div className="invalid-feedback">{errors.segment?.message}</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label"> Entry Price </label>
                                                        <div>
                                                            <input
                                                                className="form-control"
                                                                name="t2"
                                                                type="text"
                                                                value={LTP}
                                                                onChange={(e) => setLTP(e.target.value.replace(/[^0-9]/g, ''))}
                                                                placeholder="Enter Entry Price"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label"> Trade Close Price </label>
                                                        <div>
                                                            <input
                                                                className="form-control"
                                                                name="t2"
                                                                type="number"
                                                                value={closeValue}
                                                                step={0.01}
                                                                onChange={(e) => setCloseValue(e.target.value.replace(/[^0-9.]/g, ''))}
                                                                placeholder="Enter Trade Close Price"
                                                                required
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label">Stop Loss OR Risk </label>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="price">Price:</label>
                                                                <input className="form-control red-input" id="siret" type="text" placeholder="Please enter Price" onChange={handleSLPriceChange} value={stopLoss.price} required />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>

                                                                <label htmlFor="percentage">Percentage:</label>
                                                                <div>

                                                                    <input
                                                                        className="form-control red-input"
                                                                        type="text"
                                                                        step={0.01}
                                                                        placeholder="Please enter Numbers"
                                                                        onChange={handleSLPercentageChange}
                                                                        value={stopLoss.percentage}
                                                                        required
                                                                        onInvalid={(e) => {
                                                                            e.target.setCustomValidity('Please enter stoploss');
                                                                        }}
                                                                        onInput={(e) => e.target.setCustomValidity('')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {stopLossMessage && (
                                                            <div style={{ color: 'red' }}>
                                                                {stopLossMessage}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label"> Target Price </label>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="price">Price:</label>
                                                                <input className="form-control green-input" type="text" placeholder="Please enter Price" onChange={handleTargetPriceChange} value={target.price} required />


                                                            </div>
                                                            <div className='col-md-6'>
                                                                <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                                <label htmlFor="percentage">Percentage:</label>
                                                                <input
                                                                    className="form-control green-input"
                                                                    type="text"
                                                                    placeholder="Please enter Numbers"
                                                                    onChange={handleTargetPercentageChange}
                                                                    value={target.percentage}
                                                                    required
                                                                    autocomplete="off"
                                                                    step={0.01}
                                                                    onInvalid={(e) => {
                                                                        e.target.setCustomValidity('Please enter profit');
                                                                    }}
                                                                    onInput={(e) => e.target.setCustomValidity('')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {targetMessage && (
                                                            <div style={{ color: 'red' }}>
                                                                {targetMessage}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label"> Trade Risk on Capital [In Percentage] </label>
                                                        <div>
                                                            <Controller
                                                                name="trade_risk"
                                                                control={control}
                                                                rules={{ required: 'Please select trade risk' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={tradeRiskOptions}
                                                                        value={risk?.value ? { label: risk.value, value: risk.value } : null} // Check if risk.value exists
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            setRisk({ value: selectedOption.label, type: 'Percentage' });
                                                                        }}
                                                                        placeholder="Select Trade Risk"
                                                                        isSearchable={true}
                                                                    // required
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold"> Potential Profit On Capital [In Percentage] </label>
                                                        <div>
                                                            <input
                                                                className="form-control green-input"
                                                                name="t3"
                                                                type="text"
                                                                value={profit?.value}
                                                                readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label"> Enter Publish Entry Date Time </label>
                                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoItem >
                                                                        <MobileDateTimePicker onChange={(dateTime) => setSelectedDate(dateTime)} defaultValue={dayjs()} />
                                                                    </DemoItem>
                                                                </LocalizationProvider> */}
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            onChange={(date) => setSelectedDate(date)}
                                                            timeInputLabel="Time:"
                                                            className='form-control'
                                                            showTimeInput
                                                            placeholderText="Select Date Time"
                                                            dateFormat="dd/MM/yyyy h:mm aa"
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label">Enter Publish Exit Date Time </label>
                                                        <div>
                                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoItem >
                                                                        <MobileDateTimePicker onChange={(dateTime) => setExitSelectedDate(dateTime)} defaultValue={dayjs()} />
                                                                    </DemoItem>
                                                                </LocalizationProvider> */}
                                                            <DatePicker
                                                                selected={exitSelectedDate}
                                                                onChange={(date) => setExitSelectedDate(date)}
                                                                timeInputLabel="Time:"
                                                                className='form-control'
                                                                showTimeInput
                                                                placeholderText="Select Date Time"
                                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold"> Upload Report PDF </label>
                                                        <div>
                                                            <input type="file" {...register('report_pdf1')} className="form-control" onChange={handlePDFInput} />
                                                            {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                                                            <div className="invalid-feedback">{reportErr}</div>
                                                        </div>
                                                        {pdf != '' && (<span class="loc-caption">
                                                            <div className="mb-3">

                                                                <a href={pdf} target="_blank" rel="noreferrer" style={{ color: 'blue' }}> Click Here To View PDF </a>
                                                            </div>
                                                        </span>)}
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label className="font-weight-bold"> Upload Entry Chart Image </label>
                                                        <div>
                                                            <input type="file" className="form-control" id="inputGroupFile01" {...register('chart_image1')} onChange={(e) => handleFileInput(e, 0)} />

                                                            <div className="invalid-feedback">{imageErr}</div>
                                                        </div>
                                                        {image != '' && (<span class="loc-caption">
                                                            <div className="mb-3">
                                                                <div className="profile-pic">

                                                                    <img src={image} id="output" width="200" />
                                                                </div>
                                                            </div>
                                                        </span>)}
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label className="font-weight-bold"> Upload Exit Chart Image </label>
                                                        <div>
                                                            <input type="file" className="form-control" id="inputGroupFile01" {...register('chart_image2')} onChange={(e) => handleFileInput(e, 1)} />

                                                            <div className="invalid-feedback">{imageErr}</div>
                                                        </div>
                                                        {exitimage != '' && (<span class="loc-caption">
                                                            <div className="mb-3">
                                                                <div className="profile-pic">

                                                                    <img src={exitimage} id="output" width="200" />
                                                                </div>
                                                            </div>
                                                        </span>)}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold required-label mr-1"> Entry Zone [In Rs.] </label>  {tradeDirection == 2 ? (
                                                            <>
                                                                <b style={{ color: 'red' }}>Target Price : </b> {target.price}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <b style={{ color: 'red' }}>Entry Price : </b> {target.entryPrice}
                                                            </>
                                                        )}
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="buyingLowerRange">Lower Range:</label>
                                                                <div>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="lowerRange"
                                                                        min={0}
                                                                        step={0.1}
                                                                        value={buyingZone.lowerRange}
                                                                        onChange={handleBuyingZoneChange}
                                                                        placeholder="Please enter price"
                                                                        required
                                                                        onInvalid={(e) => e.target.setCustomValidity('Please enter buying zone lower range')}
                                                                        onInput={(e) => e.target.setCustomValidity('')}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <b>Calculated Lower Price:</b> {buyingZone.lowerPrice}
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="buyingUpperRange">Upper Range:</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="upperRange"
                                                                    step={0.1}
                                                                    value={buyingZone.upperRange}
                                                                    onChange={handleBuyingZoneChange}
                                                                    placeholder="Please enter price"
                                                                    required
                                                                    onInvalid={(e) => e.target.setCustomValidity('Please enter buying zone upper range')}
                                                                    onInput={(e) => e.target.setCustomValidity('')}
                                                                />
                                                                <div>
                                                                    <b>Calculated Upper Price:</b> {buyingZone.upperPrice}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold "> Setup </label>
                                                        <div>
                                                            <Controller
                                                                name="setup"
                                                                control={control}
                                                                rules={{ required: 'Please select setup' }}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        options={setupOptions}
                                                                        value={selectSetup}
                                                                        onChange={(selectedOption) => {
                                                                            field.onChange(selectedOption);
                                                                            setSelectSetup(selectedOption);
                                                                        }}
                                                                        placeholder="Select Setup"
                                                                        isSearchable={true}

                                                                        isMulti
                                                                    />
                                                                )}
                                                            />

                                                        </div>
                                                        <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="font-weight-bold mr-1"> Exit Zone[In Rs.] </label> {tradeDirection == 2 ? (
                                                            <>
                                                                <b style={{ color: 'red' }}>Entry Price : </b> {target.entryPrice}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <b style={{ color: 'red' }}>Target Price : </b> {target.price}
                                                            </>
                                                        )}
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="sellingLowerRange">Lower Range:</label>
                                                                <div>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="lowerRange"
                                                                        min={0}
                                                                        step={0.1}
                                                                        value={sellingZone.lowerRange}
                                                                        onChange={handleSellingZoneChange}
                                                                        placeholder="Please enter price"

                                                                    />
                                                                </div>
                                                                <div>
                                                                    <strong>Calculated Lower Price:</strong> {sellingZone.lowerPrice}
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="sellingUpperRange">Upper Range:</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="upperRange"
                                                                    min={0}
                                                                    step={0.1}
                                                                    value={sellingZone.upperRange}
                                                                    onChange={handleSellingZoneChange}
                                                                    placeholder="Please enter price"

                                                                />
                                                                <div>
                                                                    <strong>Calculated Upper Price:</strong>  {sellingZone.upperPrice}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom">
                                                    Update
                                                </button>
                                                <button className="btn btn-danger btn-custom-cancel" onClick={redirect}> Cancel </button>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}