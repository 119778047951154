/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addHistoricalSignal, getCategories, getSymbolExpiryList, getSymbols, getFnOSymbols } from '../../api/apiHandler';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Select from 'react-select';
import S3 from 'react-aws-s3';
import { segmentOptions, investingSegmentOptions, indexStockOptions, setupOptions, tradeRiskOptions } from '../../globals/constant';
import { getStockLTP, getFNOStockLTP, getSymbolTrades, getOptionsTrades, uploadImageS3 } from '../../common/Common'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

window.Buffer = window.Buffer || require("buffer").Buffer;



export default function AddHistoricalSignal() {

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required("Please enter signal name").max(128, "Signal name must be at most 128 character"),
        // trade_category: Yup.object().required("Please select trade category"),
        // symbols: Yup.object().required("Please select symbol"),
        // buying_zone: Yup.string().required("Please enter buy percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // selling_zone: Yup.string().required("Please enter sell percentage").matches('^[0-9]', 'Only numbers are allowed for this field'),
        // chart_image: Yup.string().required("Please upload chart image"),
        // stype: Yup.string().required('Signal type is required').nullable(),
        // trade_direction: Yup.string().required('Trade direction is required').nullable(),

        // ep: Yup.array().min(1).of(Yup.string().required('Entry price is required')).required('Entry price is required').nullable(),
        // tp: Yup.array().min(1).of(Yup.string().required('Target price is required')).required('Target price is required').nullable(),
        // rs: Yup.array().min(1).of(Yup.string().required('Risk is required')).required('Risk is required').nullable(),
    });
    const percentageInputRef = useRef(null)
    const formOptions = {
        mode: "onChange", resolver: yupResolver(validationSchema), defaultValues: {
            segment: { label: 'Equity Cash', value: '1' },
        },
    };
    const { control, register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;
    const [type, setType] = useState(false)
    const [risk, setRisk] = useState({ value: 0, type: '' })
    const [imageErr, setImageErr] = useState('');
    const [reportErr, setReportErr] = useState('');
    const [tradeDirection, setTradeDirection] = useState(1);
    const [expiryList, setExpiryList] = useState([]);
    const [tradeType, setTradeType] = useState(1);
    const [symbolList, setSymbolList] = useState(null)
    const [fnoList, setFnoList] = useState(null);
    const [symbolOptions, setSymbolOptions] = useState(null);
    const [radioType, setRadioType] = useState(2);
    const [symbol, setSymbol] = useState();
    const [selectCategory, setSelectCategory] = useState(null);
    const [chartImage, seChartImage] = useState(null);
    const [reportPDF, setReportPDF] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [categories, setCategpries] = useState(null);
    const [segment, setSegment] = useState({ label: 'Equity Cash', value: '1' });
    const [LTP, setLTP] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [options, setOptions] = useState(null);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [newTrade, setNewTrade] = useState(null);
    const [profit, setProfit] = useState({ value: 0, type: '' });
    const [stopLoss, setStopLoss] = useState({ percentage: '', price: '' });
    const [target, setTarget] = useState({ percentage: '', price: '' });
    const [tradesData, setTradesData] = useState([]);
    const [buyingZone, setBuyingZone] = useState({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' });
    const [sellingZone, setSellingZone] = useState({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' });
    const [riskRewardRatio, setRiskRewardRatio] = useState(0)
    const [selectSetup, setSelectSetup] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [exitSelectedDate, setExitSelectedDate] = useState(null);
    const [closeValue, setCloseValue] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stopLossMessage, setStopLossMessage] = useState('');
    const [targetMessage, setTagetMessage] = useState('');
    const [exitChartImage, setExitChartImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState({
        lowerRange: '',
        upperRange: ''
    });
    const [errorExitMessage, setErrorExitMessage] = useState({
        lowerRange: '',
        upperRange: ''
    });

    const   handleBuyingZoneChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\d*\.?\d*$/;
        let SLPrice = stopLoss.price; // Stop Loss Price (you need to set this from props or state)
        let TargetPrice = target.price; // Target Price (you need to set this from props or state)

        if (regex.test(value)) {
            const updatedValues = { [name]: value };
            const errors = { ...errorMessage }; // Create a copy of the current errors
            if (name === 'lowerRange') {
                const price = parseFloat(LTP - parseFloat(value)).toFixed(2);
                console.log("HERE---------------------", price);
                if (tradeDirection == 2) {
                    if (parseFloat(price) < TargetPrice) {
                        updatedValues['lowerPrice'] = price;
                        errors.lowerRange = 'Lower Range cannot be less than the Target Price';
                        console.log("IF******************");

                    } else {
                        errors.lowerRange = ''; // Clear the error message
                        updatedValues['lowerPrice'] = price;
                    }
                } else {
                    if (parseFloat(price) < SLPrice) {
                        updatedValues['lowerPrice'] = price;
                        errors.lowerRange = 'Lower Range cannot be less than the SL Price';
                        console.log("IF******************");

                    } else {
                        errors.lowerRange = ''; // Clear the error message
                        updatedValues['lowerPrice'] = price;
                    }
                }
            }
            else if (name === 'upperRange') {
                const Uprice = parseFloat(parseFloat(LTP) + parseFloat(value)).toFixed(2);
                
                if (tradeDirection == 2) {
                    if (parseFloat(Uprice) > SLPrice) {
                        updatedValues['upperPrice'] = Uprice;
                        errors.upperRange = 'Upper Range cannot be more than the SL Price';
                    } else {
                        errors.upperRange = ''; // Clear the error message
                        updatedValues['upperPrice'] = Uprice;
                    }
                }
                else {
                    console.log(Uprice,"--------LTP---",LTP,"-----tP---------",TargetPrice);
                    if (parseFloat(Uprice) > TargetPrice) {
                        updatedValues['upperPrice'] = Uprice;
                        errors.upperRange = 'Upper Range cannot be more than the Target Price';
                    } else {
                        errors.upperRange = ''; // Clear the error message
                        updatedValues['upperPrice'] = Uprice;
                    }
                }
            }

            setBuyingZone((prevState) => ({ ...prevState, ...updatedValues }));
            setErrorMessage(errors); // Update the error message state
        }
    };

    const handleSellingZoneChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\d*\.?\d*$/;
        const errors = { ...errorExitMessage };
        let TargetPrice = target.price; // Target Price (you need to set this from props or state)
        if (regex.test(value)) {
            let Price = target.price;
            const updatedValues = { [name]: value };
            const price = parseFloat(Price - parseFloat(value)).toFixed(2);

            if (name === 'lowerRange') {
                if (tradeDirection == 2) {
                    if (parseFloat(price) > TargetPrice) {
                        updatedValues['lowerPrice'] = price;
                        errors.lowerRange = 'Lower Range cannot be more than the Target Price';
                        console.log("IF******************");

                    } else {
                        errors.lowerRange = ''; // Clear the error message
                        updatedValues['lowerPrice'] = price;
                    }
                } else {
                    if (parseFloat(price) < LTP) {
                        updatedValues['lowerPrice'] = price;
                        errors.lowerRange = 'Lower Range cannot be less than the entry price';
                        console.log("IF******************");

                    } else {
                        errors.lowerRange = ''; // Clear the error message
                        updatedValues['lowerPrice'] = price;
                    }
                }
            } else if (name === 'upperRange') {
                const Uprice = parseFloat(parseFloat(Price) + parseFloat(value)).toFixed(2);

                if (tradeDirection == 2) {
                    if (parseFloat(Uprice) > LTP) {
                        updatedValues['upperPrice'] = Uprice;
                        errors.upperRange = 'Upper Range cannot be more than the entry Price';
                    } else {
                        errors.upperRange = ''; // Clear the error message
                        updatedValues['upperPrice'] = Uprice;
                    }
                }
                else {
                    
                    updatedValues['upperPrice'] = parseFloat(parseFloat(Price) + parseFloat(value)).toFixed(2);
                }
            }
            setSellingZone((prevState) => ({ ...prevState, ...updatedValues }));
            setErrorExitMessage(errors);
        }
    };

    const handleSLPercentageChange = (e) => {

        const percentage = e.target.value;
        const regex = /^\d*\.?\d*$/;
        if (regex.test(percentage)) { // Regex to allow only numbers
            let price;
            if (tradeDirection == '2') {
                price = (parseFloat(LTP) + (parseFloat(LTP) * (parseFloat(percentage) / 100))).toFixed(2);
            } else {
                price = (LTP - (LTP * (percentage / 100))).toFixed(2);
            }
            setStopLoss({ percentage, price });
        }


    };

    const handleSLPriceChange = (e) => {
        const price = e.target.value;
        let percentage;
        console.log(tradeDirection, "------------");
        if (tradeDirection == '2') {
            percentage = parseFloat(((price - LTP) / LTP) * 100).toFixed(2);
        } else {
            percentage = parseFloat(((LTP - price) / LTP) * 100).toFixed(2);
        }
        console.log(percentage, "---percentage---");
        setStopLoss({ percentage, price });
    };

    const handleTargetPercentageChange = (e) => {
        const percentage = (e.target.value);
        const regex = /^\d*\.?\d*$/;
        if (!isNaN(e.target.value) && regex.test(e.target.value)) {
            let price;
            if (tradeDirection == '2') {
                price = (LTP * (1 - percentage / 100)).toFixed(2);
                // price = (parseFloat(LTP) + (parseFloat(LTP) * (parseFloat(percentage) / 100))).toFixed(2);
            } else {
                price = (LTP * (1 + percentage / 100)).toFixed(2);
            }
            setTarget({ percentage, price });
        } else {
            setTarget({ percentage: '', price: '' });
        }
    };

    const handleTargetPriceChange = (e) => {
        const price = (e.target.value);
        if (!isNaN(price)) {
            let percentage;
            if (tradeDirection == "2") {
                percentage = parseFloat(((LTP - price) / LTP) * 100).toFixed(2);
            }
            else {
                percentage = parseFloat(((price - LTP) / LTP) * 100).toFixed(2);
            }
            setTarget({ percentage, price });
        } else {
            setTarget({ percentage: '', price: '' });
        }
    };
    useEffect(() => {
        if (stopLoss.price) {
            const stopLossPrice = Number(stopLoss.price);
            if (tradeDirection === 1 && stopLossPrice >= LTP) {
                console.log("case 1");
                setStopLossMessage('Stoploss price must be less than Entry price');
            } else if (tradeDirection === 2 && stopLossPrice <= LTP) {
                console.log("case 2");

                setStopLossMessage('Stoploss price must be greater than Entry price');
            } else {
                setStopLossMessage('');
            }
        }
    }, [stopLoss.price, tradeDirection, LTP, stopLossMessage]);

    useEffect(() => {
        if (target.price) {
            const targetPrice = Number(target.price);
            if (tradeDirection === 1 && targetPrice <= LTP) {
                console.log("case 1");
                setTagetMessage('Target price must be greater than Entry price');
            } else if (tradeDirection === 2 && targetPrice >= LTP) {
                console.log("case 2");
                setTagetMessage('Target price must be less than Entry price');
            } else {
                setTagetMessage('');
            }
        }
    }, [target.price, tradeDirection, LTP]);
    const t = type != false ? type : '';
    useEffect(() => {
        getSymbols({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setSymbolList(response.data)
            }
        })
    }, [])

    useEffect(() => {
        function fetchList() {

            if (type === 'Investing' || segment?.value === "1") {
                setOptions(symbolList);
                setSymbolOptions(false);
            }

            else if (type === 'Trading' && (segment?.value === "3" || segment?.value === "5")) {
                setOptions(indexStockOptions)
                setSymbolOptions(true);
            }
            else {
                setOptions(fnoList)
                setSymbolOptions(true);
            }

        }
        fetchList()
    }, [symbolOptions, symbolList, type, segment, options, fnoList])


    useEffect(() => {
        getFnOSymbols({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setFnoList(response.data)
            }
        })
    }, [])
    useEffect(() => {
        if (stopLoss.price && LTP && closeValue) {
            let value;
            if (tradeDirection == "2") {
                value = (LTP - closeValue) / (stopLoss.price - LTP).toFixed(2);
            } else {
                value = (closeValue - LTP) / (LTP - stopLoss.price).toFixed(2);
            }
            setRiskRewardRatio(value.toFixed(2))
            const profitValue = (value * risk?.value).toFixed(2);
            setProfit({ value: profitValue, type: 'Percentage' });
        }
    }, [ risk, stopLoss, LTP, closeValue, tradeDirection])
    // useEffect(() => {
    //     if (showForm && symbol && type == 'Trading' && segment && segment.value !== '1' && segment.value !== '6') {

    //         if (segment.value === '2' || segment.value === '3') {

    //             getSymbolTrades(symbol.value).then((res) => {
    //                 if (res) {
    //                     setTradesData(res)

    //                     if (!newTrade) {
    //                         setNewTrade(res[0].symbol)
    //                         setSelectedCheckbox(res[0].expiry)
    //                     }
    //                 }
    //             });
    //         }
    //         if ((segment.value === '4' || segment.value === '5')) {
    //             getSymbolExpiryList({ "symbol": symbol.value }).then((response) => {
    //                 response = JSON.parse(response)
    //                 if (response.code == 200) {
    //                     setExpiryList(response.data)
    //                     if (!selectedCheckbox) {
    //                         setSelectedCheckbox(response.data[0].expiry)
    //                     }
    //                     const dateWithoutDashes = selectedCheckbox.replace(/-/g, "");
    //                     getOptionsTrades(symbol, segment, tradeType, dateWithoutDashes, LTP).then((res) => {
    //                         if (res) {
    //                             setTradesData(res)
    //                             if (!newTrade) {
    //                                 setNewTrade(res[0].symbol)
    //                             }
    //                         }
    //                     });
    //                 }
    //             })
    //         }

    //         if (newTrade) {
    //             getFNOStockLTP(newTrade, symbol.value).then((res) => {
    //                 setLTP(res.ltp)
    //             });
    //         }


    //     }
    // }, [showForm, type, segment, symbol, selectedCheckbox, tradeType, newTrade, LTP])
    const navigate = useNavigate();
    function onClickEdit() {
        setImageErr('')
        setReportErr('');
        setSymbol(null)
        seChartImage(null)
        setExitChartImage(null)
        setReportPDF(null)
        setSellingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setBuyingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setSymbol(null);
        setLTP(null);
        setExpiryList([]);
        setTradesData([]);
        setTarget({ percentage: '', price: '' });
        setStopLoss({ percentage: '', price: '' });
        setProfit({ value: 0, type: '' });
        setRisk({ value: 0, type: '' });
        setNewTrade(null);
        setSelectedCheckbox(null);
    }

    function redirect() {
        navigate("/past_perfomance")
    }

    useEffect(() => {
        setTarget({ percentage: '', price: '' });
        setStopLoss({ percentage: '', price: '' });
        setProfit({ value: 0, type: '' });
        setRisk({ value: 0, type: '' });
        setSellingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setBuyingZone({ lowerRange: '', upperRange: '', lowerPrice: '', upperPrice: '' })
        setLTP(null)
    }, [newTrade])
    // For Signal Categoey [ Investing and trading]
    const handleSignalCategoryChange = (event) => {
        const value = event.target.value;
        setType(value)
        getCategoryList(value == "Investing" ? "1" : "2")
    };


    // Handle Symbol Change 
    const handleSymbolChange = async (selectedOption) => {
        let stockValue;
        if (symbolOptions) {
            const params = segment.value === "3" || segment.value === "5" ? selectedOption.ltp_index : selectedOption.value;
            stockValue = await getFNOStockLTP(params, selectedOption.value);
        }
        else {
            stockValue = await getStockLTP(selectedOption.value);
        }
        if (stockValue) {
            setSymbol(selectedOption);
            setLTP(stockValue.ltp)
        }
    }
    function getCategoryList(type) {
        getCategories({ "type": type, "module": 2 }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                setCategpries(response.data)
            }
        })
    }
    //s3 file upload - Chart image
    const handleFileInput = async (e, is_exit_image) => {
        const imageName = await uploadImageS3({ "dir_name": 'chart_image', 'file': e.target.files[0] })
        if (is_exit_image === 1) {
            setExitChartImage(imageName)
        }
        else {
            seChartImage(imageName)
        }
    }
    //s3 file upload - Report PDF
    const handlePDFInput = async (e) => {
        const pdfName = await uploadImageS3({ "dir_name": 'report_pdf', 'file': e.target.files[0] })
        setReportPDF(pdfName)
    }
    const onSubmit = (data) => {
        setIsSubmitting(true);
        // if (reportPDF == null) {
        //     setReportErr('Please upload report pdf')
        // }
        // else {
        addHistoricalSignal({ "category": type, "type": radioType, "symbol": symbol, "entry_price": LTP, "target_price": target, "stoploss": stopLoss, "trade_category": selectCategory, "chart_image": chartImage, "exit_chart_image": exitChartImage, "report_pdf": reportPDF, "buying_zone": buyingZone, "selling_zone": sellingZone, "trade_direction": tradeDirection, "segment": segment, "tradelog_risk": risk, "profit": profit, "risk_reward_ratio": riskRewardRatio, "setup": selectSetup, "entry_date": selectedDate, "exit_date": exitSelectedDate, "trade_close_price": closeValue }).then((response) => {
            response = JSON.parse(response)
            if (response.code == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    navigate("/past_perfomance")
                }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
        setIsSubmitting(false);
        // }
    }
    return (
        <div className='content-page mt-5'>
            <div className='content'>
                <div className='container-fluid' >
                    <div className='cal col-xs-12 col-md-12 col-sm-12'>
                        <div className='full-width'>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>

                                <div style={{ marginLeft: 'auto' }}>
                                    <div className="card-container m-0">
                                        <div className="card-body p-0">
                                            <h6>Summary : </h6>
                                            <div>
                                                <label htmlFor="name" className='mr-1'>Trade Risk On Capital :</label> {risk?.value}%<br />
                                                <label htmlFor="reward-per-lot" className='mr-1'>Potential Profit On Capital :</label> {profit?.value}%<br />
                                                <label htmlFor="entry-price-range" className='mr-1'> Risk Reward Ratio:</label> {riskRewardRatio}<br />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <h5>Add {t} Historical Signal</h5>
                            <hr></hr>
                            {
                                <><meta charset="UTF-8"></meta>
                                    <div style={{ height: '470px', overflowY: 'auto', overflowX: 'hidden' }}>
                                        <form onSubmit={handleSubmit(onSubmit)} >
                                            {type == false ? <>
                                                <label className="font-weight-bold">Select Signal Category: </label>
                                                <div class="button-items">
                                                    <button type="button" class="btn btn-primary waves-effect waves-light" value="Investing" onClick={handleSignalCategoryChange}>Investing</button>
                                                    <button type="button" value="Trading" class="btn btn-secondary waves-effect" onClick={handleSignalCategoryChange}>Trading</button>
                                                </div><br />
                                                <label className="font-weight-bold">Select Type: </label>
                                                <div>
                                                    <label className='mr-3'><input type="radio" name="signalCategory" value="1" onClick={(event) => setRadioType(event.target.value)} /> Free</label>
                                                    <label className='mr-3'><input type="radio" name="signalCategory" value="2" onClick={(event) => setRadioType(event.target.value)} defaultChecked /> Premium</label>
                                                </div>
                                            </> :
                                                <>
                                                    {/* <div className="form-group">

                                                    {isClickOnEdit == 0 ? <>  <div className='row'> <button type='button' onClick={onClickEdit} className='btn btn-info waves-effect waves-light' style={{ marginLeft: 'auto' }}><i class="fa fa-edit"></i> Edit Signal Category</button> </div></> :
                                                        <>
                                                            <label className="font-weight-bold">Select Signal Cateory: </label>
                                                            <div class="button-items">
                                                                <button type="button" class="btn btn-primary waves-effect waves-light" value="Investing" onClick={handleSignalCategoryChange}>Investing</button><button type="button" value="Trading" class="btn btn-secondary waves-effect" onClick={handleSignalCategoryChange}>Trading</button></div>
                                                        </>}
                                                </div> */}
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6 d-flex '>
                                                                <label className="mr-3 font-weight-bold" htmlFor='type1'>Select Category :</label>
                                                                <div className="mr-3">
                                                                    <input
                                                                        type="radio"
                                                                        id="type1"
                                                                        name="type_signal"
                                                                        value="Investing"
                                                                        checked={type == "Investing"}
                                                                        onChange={(e) => {
                                                                            handleSignalCategoryChange(e)
                                                                        }}
                                                                        disabled={false}
                                                                    /> <label htmlFor='type1' className='ml-1'>Investing</label>
                                                                    <input
                                                                        type="radio"
                                                                        id="type2"
                                                                        name="type_signal"
                                                                        value="Trading"
                                                                        checked={type == "Trading"}
                                                                        onChange={(e) => {
                                                                            handleSignalCategoryChange(e)
                                                                        }}
                                                                        className='ml-2'
                                                                        disabled={false}
                                                                    /> <label htmlFor='type2' className='ml-1'>Trading</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>

                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label" > Tradelog Segment </label>
                                                                <div>
                                                                    <Controller
                                                                        name="segment"
                                                                        control={control}
                                                                        rules={{ required: 'Please select segment' }}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                options={type === 'Investing' ? investingSegmentOptions : segmentOptions}
                                                                                value={field.value}
                                                                                menuPortalTarget={document.body}
                                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                onChange={(selectedOption) => {
                                                                                    field.onChange(selectedOption);
                                                                                    setSegment(selectedOption)
                                                                                    setIsReadOnly((selectedOption.value === '3' || selectedOption.value === '4') && (type === 'Trading'))
                                                                                    setShowForm((selectedOption.value !== '1' && selectedOption.value !== '6') && (type === 'Trading') ? true : false);
                                                                                    onClickEdit()
                                                                                }}
                                                                                placeholder="Select Segment"
                                                                                isSearchable={true}
                                                                                required
                                                                            />
                                                                        )}
                                                                    />

                                                                    <div className="invalid-feedback">{errors.segment?.message}</div>

                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label htmlFor="mySelect2 " className='required-label'>Symbol </label>
                                                                <div className='custom-select-container'>
                                                                    <Controller
                                                                        name="content"
                                                                        control={control}
                                                                        {...register('symbols', { required: 'Please select country code' })}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                options={options}
                                                                                value={symbol}
                                                                                menuPortalTarget={document.body}
                                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                onChange={(selectedOption) => {
                                                                                    setSymbol(selectedOption);
                                                                                    // handleSymbolChange(selectedOption);
                                                                                    // field.onChange(selectedOption);
                                                                                    // onClickEdit()
                                                                                }}
                                                                                placeholder="Select Symbol"
                                                                                isSearchable={true}
                                                                                required
                                                                            />
                                                                        )}
                                                                    />

                                                                    <div className="invalid-feedback">{errors.symbols?.message}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showForm && tradesData.length > 0 ? <>
                                                            <div className="form-group">
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <label className="font-weight-bold"> Expiry Dates </label>
                                                                        <div>
                                                                            {expiryList.length > 0 ? <>

                                                                                {expiryList.map((item, index) => (
                                                                                    item.expiry ? <>
                                                                                        <label style={{ marginRight: '10px' }}>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                value={item.expiry}
                                                                                                checked={selectedCheckbox === item.expiry}
                                                                                                onChange={(event) => { setSelectedCheckbox(event.target.value); setNewTrade(null) }}
                                                                                                className='mr-1'
                                                                                            />
                                                                                            {item.expiry}
                                                                                        </label>
                                                                                    </> : <>
                                                                                    </>
                                                                                ))}
                                                                            </> : <>
                                                                                <span style={{ color: 'blue' }}> {selectedCheckbox}</span>
                                                                            </>}
                                                                        </div>
                                                                    </div>

                                                                    {showForm && tradesData.length > 0 ? <>
                                                                        <div className='col-md-6'>
                                                                            <label className="font-weight-bold required-label"> Available Trades </label>
                                                                            <div>
                                                                                {tradesData.map((item, index) => (
                                                                                    <label style={{ marginRight: '10px' }}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            value={item.symbol}
                                                                                            checked={newTrade === item.symbol}
                                                                                            onChange={(event) => {
                                                                                                setNewTrade(event.target.value);
                                                                                                if (segment.value === '2' || segment.value === '3') {
                                                                                                    setSelectedCheckbox(item.expiry);
                                                                                                }
                                                                                            }}
                                                                                            className='mr-1'
                                                                                        />
                                                                                        {item.name}
                                                                                    </label>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </> : <>

                                                                    </>}
                                                                </div>
                                                            </div></> : <> </>
                                                    }
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6 d-flex '>
                                                                <label className="mr-3 font-weight-bold">Trade Direction :</label>
                                                                <div className='mr-3'>
                                                                    <input type="radio" id="age1" name="trade_direction" value="1" nClick={(e) => {
                                                                        setTradeDirection(parseInt(e.target.value, 10));
                                                                        setTarget({ percentage: '', price: '' });
                                                                        setStopLoss({ percentage: '', price: '' });
                                                                        setStopLossMessage('')
                                                                        setTagetMessage('')
                                                                    }} defaultChecked readOnly={isReadOnly} /><label htmlFor='age1' className='ml-1'>LONG</label>
                                                                    {(type === 'Trading' && ((segment?.value === '1' && selectCategory?.label === 'Intraday') || segment?.value !== '1')) ?
                                                                        (<>
                                                                            <input type="radio" id="age2" name="trade_direction" value="2" onClick={(e) => {
                                                                                setTradeDirection(parseInt(e.target.value, 10));
                                                                                setTarget({ percentage: '', price: '' });
                                                                                setStopLoss({ percentage: '', price: '' });
                                                                                setStopLossMessage('')
                                                                                setTagetMessage('')
                                                                            }} className='ml-2' />
                                                                            <label htmlFor='age2' className='ml-1'>SHORT</label>
                                                                        </>)
                                                                        : <></>}
                                                                </div>
                                                                {
                                                                    (segment && (segment.value === '5' || segment.value === '4') && (type === 'Trading')) ? <>
                                                                        <label className="mr-3 ml-3 font-weight-bold">Trade Type :</label>
                                                                        <div>
                                                                            <input type="radio" id="age1" name="trade_type" value="1" onClick={(e) => { setTradeType(e.target.value); setNewTrade(null) }} defaultChecked /><label htmlFor='age1' className='ml-1'>LONG</label>
                                                                            <input type="radio" id="age2" name="trade_type" value="2" onClick={(e) => { setTradeType(e.target.value); setNewTrade(null) }} className='ml-2' /><label htmlFor='age2' className='ml-1'>SHORT</label>
                                                                            <div className="invalid-feedback">{errors.trade_type?.message}</div>
                                                                        </div>
                                                                    </> : <>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label"> Trade Category </label>
                                                                <div>
                                                                    <Controller
                                                                        name="trade_category"
                                                                        control={control}
                                                                        rules={{ required: 'Please select trade category' }}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                options={categories}
                                                                                value={field.value}
                                                                                onChange={(selectedOption) => {
                                                                                    field.onChange(selectedOption);
                                                                                    setSelectCategory(selectedOption);
                                                                                }}
                                                                                placeholder="Select Trade Category"
                                                                                isSearchable={true}
                                                                                required
                                                                            />
                                                                        )}
                                                                    />

                                                                </div>
                                                                <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label"> Entry Price </label>
                                                                <div>
                                                                    <input
                                                                        className="form-control"
                                                                        name="t2"
                                                                        type="text"
                                                                        value={LTP}
                                                                        step={0.01}
                                                                        autocomplete="off"
                                                                        onChange={(e) => setLTP(e.target.value.replace(/[^\d\.]/g, ''))}
                                                                        placeholder="Enter Entry Price"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label"> Trade Close Price </label>
                                                                <div>
                                                                    <input
                                                                        className="form-control"
                                                                        name="t2"
                                                                        type="text"
                                                                        value={closeValue}
                                                                        step={0.01}
                                                                        autocomplete="off"
                                                                        onChange={(e) => setCloseValue(e.target.value.replace(/[^\d\.]/g, ''))}
                                                                        placeholder="Enter Trade Close Price"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label">Stop Loss OR Risk </label>
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <label htmlFor="price">Price:</label>
                                                                        <div>

                                                                            <input className="form-control red-input" id="siret" type="text" placeholder="Please enter Price" step={0.01} autocomplete="off" onChange={handleSLPriceChange} value={stopLoss.price} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                                        <label htmlFor="percentage">Percentage:</label>
                                                                        <input
                                                                            className="form-control red-input"
                                                                            type="text"
                                                                            placeholder="Please enter percentage"
                                                                            onChange={handleSLPercentageChange}
                                                                            value={stopLoss.percentage}
                                                                            required
                                                                            autocomplete="off"
                                                                            step="0.01" // allow decimal steps
                                                                            onInvalid={(e) => {
                                                                                e.target.setCustomValidity('Please enter a valid percentage');
                                                                            }}
                                                                            onInput={(e) => e.target.setCustomValidity('')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {stopLossMessage && (
                                                                    <div style={{ color: 'red' }}>
                                                                        {stopLossMessage}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label"> Target Price </label>
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <label htmlFor="price">Price:</label>
                                                                        <div>
                                                                            <input className="form-control green-input" type="text" placeholder="Please enter Price" autocomplete="off" step={0.01} onChange={handleTargetPriceChange} value={target.price} required />

                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <span style={{ marginLeft: '-23px', marginRight: '6px' }}>OR</span>
                                                                        <label htmlFor="percentage">Percentage:</label>
                                                                        <input
                                                                            className="form-control green-input"
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            placeholder="Please enter percentage"
                                                                            onChange={handleTargetPercentageChange}
                                                                            value={target.percentage}
                                                                            required
                                                                            step="0.01" // allow decimal steps
                                                                            onInvalid={(e) => {
                                                                                e.target.setCustomValidity('Please enter a valid percentage');
                                                                            }}
                                                                            onInput={(e) => e.target.setCustomValidity('')}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {targetMessage && (
                                                                    <div style={{ color: 'red' }}>
                                                                        {targetMessage}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold"> Trade Risk on Capital [In Percentage] </label>
                                                                <div>
                                                                    <Controller
                                                                        name="trade_risk"
                                                                        control={control}
                                                                        rules={{ required: 'Please select trade risk' }}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                options={tradeRiskOptions}
                                                                                value={field.value}
                                                                                onChange={(selectedOption) => {
                                                                                    field.onChange(selectedOption);
                                                                                    setRisk({ value: selectedOption.label, type: 'Percentage' });
                                                                                }}
                                                                                placeholder="Select Trade Risk"
                                                                                isSearchable={true}
                                                                            // required
                                                                            />
                                                                        )}
                                                                    />

                                                                </div>
                                                                <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold"> Profit On Capital [In Percentage] </label>
                                                                <div>
                                                                    <input
                                                                        className="form-control green-input"
                                                                        name="t3"
                                                                        type="text"
                                                                        value={profit?.value}
                                                                        readOnly />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label"> Enter Publish Entry Date Time </label>
                                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoItem >
                                                                        <MobileDateTimePicker onChange={(dateTime) => setSelectedDate(dateTime)} defaultValue={dayjs()} />
                                                                    </DemoItem>
                                                                </LocalizationProvider> */}
                                                                <DatePicker
                                                                    selected={selectedDate}
                                                                    onChange={(date) => setSelectedDate(date)}
                                                                    timeInputLabel="Time:"
                                                                    className='form-control'
                                                                    showTimeInput
                                                                    placeholderText="Select Date Time"
                                                                    dateFormat="dd/MM/yyyy h:mm aa"
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label">Enter Publish Exit Date Time </label>
                                                                <div>
                                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DemoItem >
                                                                        <MobileDateTimePicker onChange={(dateTime) => setExitSelectedDate(dateTime)} defaultValue={dayjs()} />
                                                                    </DemoItem>
                                                                </LocalizationProvider> */}
                                                                    <DatePicker
                                                                        selected={exitSelectedDate}
                                                                        onChange={(date) => setExitSelectedDate(date)}
                                                                        timeInputLabel="Time:"
                                                                        className='form-control'
                                                                        showTimeInput
                                                                        placeholderText="Select Date Time"
                                                                        dateFormat="dd/MM/yyyy h:mm aa"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold"> Upload Report PDF </label>
                                                                <div>
                                                                    <input type="file" {...register('report_pdf1')} className="form-control" onChange={handlePDFInput} />
                                                                    {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                                                                    <div className="invalid-feedback">{reportErr}</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label className="font-weight-bold"> Upload Entry Chart Image </label>
                                                                <div>
                                                                    <input type="file" className="form-control" id="inputGroupFile01" {...register('chart_image1')} onChange={(e) => handleFileInput(e, 0)} />

                                                                    <div className="invalid-feedback">{imageErr}</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label className="font-weight-bold"> Upload Exit Chart Image </label>
                                                                <div>
                                                                    <input type="file" className="form-control" id="inputGroupFile01" {...register('chart_image1')} onChange={(e) => handleFileInput(e, 1)} />

                                                                    <div className="invalid-feedback">{imageErr}</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label mr-1"> Entry Zone [In Rs.] </label>
                                                                <>
                                                                    <b style={{ color: 'red' }}>Entry Price : </b> {LTP}
                                                                </>

                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <label htmlFor="buyingLowerRange">Lower Range:</label>
                                                                        <div>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                name="lowerRange"
                                                                                min={0}
                                                                                step={0.1}
                                                                                value={buyingZone.lowerRange}
                                                                                onChange={handleBuyingZoneChange}
                                                                                placeholder="Please enter price"
                                                                                required
                                                                                onInvalid={(e) => e.target.setCustomValidity('Please enter buying zone lower range')}
                                                                                onInput={(e) => e.target.setCustomValidity('')}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <b>Calculated Lower Price:</b> {buyingZone.lowerPrice}
                                                                        </div>
                                                                        {errorMessage.lowerRange && (
                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                            {errorMessage.lowerRange}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <label htmlFor="buyingUpperRange">Upper Range:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            name="upperRange"
                                                                            step={0.1}
                                                                            value={buyingZone.upperRange}
                                                                            onChange={handleBuyingZoneChange}
                                                                            placeholder="Please enter price"
                                                                            required
                                                                            autocomplete="off"
                                                                            onInvalid={(e) => e.target.setCustomValidity('Please enter buying zone upper range')}
                                                                            onInput={(e) => e.target.setCustomValidity('')}
                                                                        />
                                                                        <div>
                                                                            <b>Calculated Upper Price:</b> {buyingZone.upperPrice}
                                                                        </div>
                                                                        {errorMessage.upperRange && (
                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                            {errorMessage.upperRange}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold required-label"> Setup </label>
                                                                <div>
                                                                    <Controller
                                                                        name="setup"
                                                                        control={control}
                                                                        rules={{ required: 'Please select setup' }}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                options={setupOptions}
                                                                                value={field.value}
                                                                                onChange={(selectedOption) => {
                                                                                    field.onChange(selectedOption);
                                                                                    setSelectSetup(selectedOption);
                                                                                }}
                                                                                placeholder="Select Setup"
                                                                                autocomplete="off"
                                                                                isSearchable={true}
                                                                                required
                                                                                isMulti
                                                                            />
                                                                        )}
                                                                    />

                                                                </div>
                                                                <div className="invalid-feedback">{errors.trade_category?.message}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className="font-weight-bold mr-1"> Exit Zone[In Rs.] </label>

                                                                <>
                                                                    <b style={{ color: 'red' }}>Target Price : </b> {target.price}
                                                                </>

                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <label htmlFor="sellingLowerRange">Lower Range:</label>
                                                                        <div>
                                                                            <input
                                                                                className="form-control"
                                                                                type="text"
                                                                                name="lowerRange"
                                                                                autocomplete="off"
                                                                                min={0}
                                                                                step={0.1}
                                                                                value={sellingZone.lowerRange}
                                                                                onChange={handleSellingZoneChange}
                                                                                placeholder="Please enter price"

                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <strong>Calculated Lower Price:</strong> {sellingZone.lowerPrice}
                                                                        </div>
                                                                        {errorExitMessage.lowerRange && (
                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                            {errorExitMessage.lowerRange}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <label htmlFor="sellingUpperRange">Upper Range:</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            autocomplete="off"
                                                                            name="upperRange"
                                                                            min={0}
                                                                            step={0.1}
                                                                            value={sellingZone.upperRange}
                                                                            onChange={handleSellingZoneChange}
                                                                            placeholder="Please enter price"

                                                                        />
                                                                        <div>
                                                                            <strong>Calculated Upper Price:</strong>  {sellingZone.upperPrice}
                                                                        </div>
                                                                        {errorExitMessage.upperRange && (
                                                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                                                            {errorExitMessage.upperRange}
                                                                        </div>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary waves-effect waves-light m-2 btn-custom" disabled={isSubmitting}>
                                                            Add
                                                        </button>
                                                        <button className="btn btn-danger btn-custom-cancel" onClick={redirect}> Cancel </button>
                                                    </div>
                                                </>}
                                        </form>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}