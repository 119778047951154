import React, { useEffect, useState, useRef } from 'react'
import { getKycCompletedUsers } from '../../api/apiHandler';
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';

function KYCmemberList() {
    const debounceTimeout = useRef(null);


    const [userList, setUserList] = useState(null);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");

    function fetchList() {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getKycCompletedUsers({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": searchQuery }).then((response) => {
            Swal.close()

            response = JSON.parse(response)

            if (response.code == 200) {
                setUserList(response.data.kycMemberList);
                setTotalRecordCount(Math.ceil(response.data.kycMembersCount / process.env.REACT_APP_RECORD_COUNT));
            } else {
                setUserList([])
                setTotalRecordCount(0)
            }
        })
    }
    useEffect(() => {
        fetchList();
    }, [page]);

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    const handleSearch = (event) => {
        // Clear the existing timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            setPage(1);
            // Dispatch the action with the updated state
            getKycCompletedUsers({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": event }).then((response) => {
                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setUserList(response.data.kycMemberList);
                    setTotalRecordCount(Math.ceil(response.data.kycMembersCount / process.env.REACT_APP_RECORD_COUNT));
                } else {
                    setUserList([])
                    setTotalRecordCount(0)
                }
            })
        }, 1000);
    };

    const iamge = 'https://stockwiz-dev.s3.ap-south-1.amazonaws.com/kyc_images/1666653b-3505-4402-bd47-9fbbff53e9de.jpg'

    if (userList == null) return <> </>
    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="row justify-content-between mb-2 float-right">
                                <div className="col-auto">
                                    <input type="text" className="form-control" placeholder="Search..." onChange={(e) => { handleSearch(e.target.value); setSearchQuery(e.target.value); }} />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th style={{ textAlign: "center" }}>ID</th>
                                            <th style={{ textAlign: "center" }}>First Name</th>
                                            <th style={{ textAlign: "center" }}>Last Name</th>
                                            <th style={{ textAlign: "center" }}>Country Code</th>
                                            <th style={{ textAlign: "center" }}>Mobile Number</th>
                                            <th style={{ textAlign: "center" }}>Aadhar Card Number</th>
                                            <th style={{ textAlign: "center" }}>Aadhar Card Front Image</th>
                                            <th style={{ textAlign: "center" }}>Aadhar Card Back Image</th>
                                            <th style={{ textAlign: "center" }}>Pan Card Number</th>
                                            <th style={{ textAlign: "center" }}>Pan Card Image</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList && userList.length > 0 ?
                                            (
                                                userList && userList?.map((item, index) => {
                                                    return (
                                                        <tr tr id={"userId" + item._id} >
                                                            <td style={{ textAlign: "center" }}>
                                                                <span className="fw-semibold">{index + 1 + ((page - 1) * process.env.REACT_APP_RECORD_COUNT)}</span>
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {item.kyc_first_name}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {item.kyc_last_name}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {item.country_code}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {item.mobile_number}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {item.aadhar_card_details.card_number}
                                                            </td>

                                                            <td style={{ textAlign: "center" }}>
                                                                <div>

                                                                    {item.aadhar_card_details.front_page_url && (
                                                                        <>
                                                                            <a href={item.aadhar_card_details.front_page_url} target="_blank" rel="noopener noreferrer" className="image-popup">
                                                                                <button type="button" className='btn-mute' style={{ cursor: "pointer" }}>View Image</button>
                                                                            </a>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <div>

                                                                    {item.aadhar_card_details.back_page_url && (
                                                                        <>
                                                                            <a href={item.aadhar_card_details.back_page_url} target="_blank" rel="noopener noreferrer" className="image-popup">
                                                                                <button type="button" className='btn-mute' style={{ cursor: "pointer" }}>View Image</button>
                                                                            </a>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {item?.pan_card_details?.card_number}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <div>
                                                                    {item.pan_card_details.pan_card_image_url && (
                                                                        <>

                                                                            <a href={item.pan_card_details.pan_card_image_url} target="_blank" rel="noopener noreferrer" className="image-popup">
                                                                                <button type="button" className='btn-mute' style={{ cursor: "pointer" }}>View Image</button>
                                                                            </a>
                                                                        </>
                                                                    )}
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            ) :
                                            (
                                                // <tr>
                                                //     <td>
                                                //         <h4 className="page-title">No user data found</h4>
                                                //     </td>
                                                // </tr>
                                                <></>
                                            )
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination pagination-rounded justify-content-end my-2">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=" > "
                                    onPageChange={handlePageClick}
                                    // pageRangeDisplayed={pageCount}
                                    pageCount={totalRecordCount}
                                    previousLabel=" < "
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    disabledClassName="disabled"
                                    activeClassName="active"
                                    activeLinkClassName="active-link"
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default KYCmemberList;