import React, { useEffect, useRef, useState } from 'react'
import { getSignalList, getSubAdminById } from '../../api/apiHandler';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

function TradeLogList() {
    const debounceTimeout = useRef(null);

    const [signalList, setSignalList] = useState(null)
    const [signalRemove, setSignalRemove] = useState(0)
    const [signalChangeStatus, setSignalChangeStatus] = useState(0)
    const [signalEdit, setSignalEdit] = useState(0)
    const [signalAdd, setSignalAdd] = useState(0)
    const [signalView, setSignalView] = useState(0)
    const [page, setPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    

    useEffect(() => {
        if (localStorage.getItem("Arole") == "S") {
            setSignalRemove(1)
            setSignalChangeStatus(1)
            setSignalEdit(1)
            setSignalAdd(1)
            setSignalView(1)
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            getSignalList({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, }).then((response) => {
                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data.response)
                    setTotalRecordCount(Math.ceil(response.data.totalCount / process.env.REACT_APP_RECORD_COUNT));
                } else {
                    setSignalList([])
                    setTotalRecordCount(0);
                }
            })
        } else {
            getSignalList({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, }).then((response) => {
                Swal.close()
                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data.response)
                    setTotalRecordCount(Math.ceil(response.data.totalCount / process.env.REACT_APP_RECORD_COUNT));
                    getSubAdminById({ "sub_admin_id": localStorage.getItem('Aid') }).then((res) => {
                        res = JSON.parse(res)
                        if (res.code == 200) {
                            setSignalEdit(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_edit === 1).length > 0
                            );
                            setSignalView(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_view === 1).length > 0
                            );
                            setSignalRemove(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_delete === 1).length > 0)
                            setSignalChangeStatus(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_change_status === 1).length > 0)
                            setSignalAdd(res.data.permissions.filter(module => module.class_name === 'tradelog' && module.is_create === 1).length > 0)
                        }
                    });
                } else {
                    setSignalList([])
                    setTotalRecordCount(0);
                }
            })
        }
    }, [])

    function renderStatusUiText(item) {
        return (
            item.is_active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>
        )
    }

    const handlePageClick = (event) => {
        setPage(event.selected + 1)
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            },
        });
        getSignalList({ "page": event.selected + 1, "limit": process.env.REACT_APP_RECORD_COUNT, }).then((response) => {
            Swal.close()
            response = JSON.parse(response)
            if (response.code == 200) {
                setSignalList(response.data.response)
                setTotalRecordCount(Math.ceil(response.data.totalCount / process.env.REACT_APP_RECORD_COUNT));
            } else {
                setSignalList([])
                setTotalRecordCount(0);
            };
        });
    };

    const handleSearch = (event) => {
        setPage(1);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            // Dispatch the action with the updated state
            getSignalList({ "page": page, "limit": process.env.REACT_APP_RECORD_COUNT, "search": event }).then((response) => {
                response = JSON.parse(response)
                if (response.code == 200) {
                    setSignalList(response.data.response)
                    setTotalRecordCount(Math.ceil(response.data.totalCount / process.env.REACT_APP_RECORD_COUNT));
                } else {
                    setSignalList([])
                    setTotalRecordCount(0);
                }
            })
        }, 1000);
    }

    if (signalList == null) return <> </>

    return (
        <>
            <div className='content-page mt-5'>
                <div className='content'>
                    <div className='container-fluid'>
                        <div className='cal'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between mb-2 float-right">
                                        <div className="col-auto">
                                            <form className="search-bar position-relative mb-sm-0 mb-2">
                                                <input type="text" className="form-control" placeholder="Search..." onChange={(e) => { handleSearch(e.target.value); setSearchQuery(e.target.value); }} />
                                            </form>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-md-end">
                                                {/* <button type="button" className="btn btn-custom waves-effect waves-light mb-2 me-2"><i className="mdi mdi-basket me-1"></i> Add User</button> */}
                                                {/* <button type="button" className="btn btn-success waves-effect waves-light mb-2 me-1"><i className="mdi mdi-cog"></i></button> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>ID</th>
                                                    <th style={{ textAlign: "center" }}>Admin Name</th>
                                                    <th style={{ textAlign: "center" }}>Name</th>
                                                    <th style={{ textAlign: "center" }}>Category</th>
                                                    <th style={{ textAlign: "center" }}>Signal Type</th>
                                                    <th style={{ textAlign: "center" }}>Segment</th>
                                                    <th style={{ textAlign: "center" }}>Strategies</th>
                                                    <th style={{ textAlign: "center" }}>Add To Strategy</th>
                                                    <th style={{ textAlign: "center" }}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    signalList && signalList.map((item, index) => {
                                                        return (
                                                            <tr id={"signalId" + item._id} >
                                                                <td style={{ textAlign: "center" }}>
                                                                    <span className="fw-semibold">{index + 1 + ((page - 1) * process.env.REACT_APP_RECORD_COUNT)}</span>
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {item.userName}
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {item.name}
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {item.category}
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {item.type == 1 ? "Free" : "Premium"}
                                                                </td>
                                                                <td id={"signalStatus" + item._id} style={{ textAlign: "center" }}>
                                                                    {item.segment}
                                                                </td>
                                                                <td id={"signalStatus" + item._id} style={{ textAlign: "center" }}>
                                                                    {item.strategyNames}
                                                                </td>
                                                                <td id={"signalStatus" + item._id} style={{ textAlign: "center" }}>
                                                                    {item.add_to_strategy == 1 ? "Yes" : "No"}
                                                                </td>
                                                                <td id={"signalStatus" + item._id} style={{ textAlign: "center" }}>
                                                                    {renderStatusUiText(item)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <ul className="pagination pagination-rounded justify-content-end my-2">
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=" > "
                                            onPageChange={handlePageClick}
                                            // pageRangeDisplayed={pageCount}
                                            pageCount={totalRecordCount}
                                            previousLabel=" < "
                                            renderOnZeroPageCount={null}
                                            containerClassName="pagination"
                                            pageLinkClassName="page-link"
                                            previousLinkClassName="page-link"
                                            nextLinkClassName="page-link"
                                            disabledClassName="disabled"
                                            activeClassName="active"
                                            activeLinkClassName="active-link"
                                            forcePage={page - 1}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TradeLogList;